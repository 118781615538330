import Head from 'next/head'

export default function Home() {
  return (
    <Head>
      <title>Bookee CRM | Client Management For Fitness Businesses</title>
      <meta
        name="description"
        content="The ultimate fitness studio CRM to manage scheduling, build marketing funnels, create billing cycles and scale your business"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  )
}
